@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;




@media screen and (min-width: 520px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 640px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 16px;
  }
} 